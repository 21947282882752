import autoBind from 'react-autobind';
import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Collapse from 'react-bootstrap/Collapse';
import ClickableTooltip from '~/components/effects/clickable_tooltip';
import Tooltipable from '~/components/effects/tooltipable';

import Drawer from '~/components/drawers/drawer';
import EmailDomainActions from '~/actions/email_domain_actions';
import EmailDomainStore from '~/stores/email_domain_store';

const TooltipText = 'If you already have an SPF record in the DNS records for this sending domain, please edit it vs. adding a duplicate one and just add “include:mailgun.org” after “v=spf1” with a space in between.';
class EmailDomainDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain:     props.domain,
      errors:     null,
      submitting: false,
      isOpen:     false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.domainStoreListener = EmailDomainStore.addListener(
      this.onDomainStoreChange,
    );
  }

  componentWillUnmount() {
    if (this.domainStoreListener) {
      this.domainStoreListener.remove();
    }
  }

  handleCopy() {
    GlobalContainer.notify('Copied to Clipboard', 'info');
  }

  onDomainStoreChange() {
    const domainStoreState = EmailDomainStore.getState();
    const { domain, lastDomainStoreAction, errors } = domainStoreState;
    let nextState;

    if (lastDomainStoreAction === 'verifyEmailDomain') {
      nextState = {
        errors,
        submitting: true,
      };
    }

    if (lastDomainStoreAction === 'verifyEmailDomainDone') {
      nextState = {
        domain,
        errors,
        submitting: false,
      };
    }

    if (lastDomainStoreAction === 'verifyEmailDomainFail') {
      nextState = {
        errors,
        submitting: false,
      };
    }

    this.setState(nextState);
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  onCancel(e) {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.closeDrawer();
  }

  getProcessedDomainName(domainName) {
    if (domainName === '_dmarc') return domainName;

    const domainParts = domainName.split('.');

    if (domainParts.length === 2) return '@';

    return domainParts.slice(0, -2).join('.');
  }

  verifyDomain() {
    const { domain } = this.state;

    if (_lodash.isEmpty(domain)) return;

    EmailDomainActions.verifyDomain(domain);
  }

  renderStatus(status) {
    let className;
    let statusName;

    switch (status) {
      case 'valid':
      case true:
        className = 'badge badge-outline-green';
        statusName = 'Valid';
        break;
      case 'unknown':
      case 'unverified':
      case false:
        className = 'badge badge-outline-warning';
        statusName = 'Pending';
        break;
      default:
        className = 'badge badge-outline-gray';
        statusName = 'Pending';
        break;
    }

    return (
      <span className={className}>{statusName.toUpperCase()}</span>
    );
  }

  renderDomainNameWithCopy(record) {
    const { domain } = this.state;
    const domainName = this.getProcessedDomainName(record.name || domain.name);

    return (
      <>
        {domainName}
        {' '}
        <CopyToClipboard
          text={domainName}
          onCopy={this.handleCopy}
        >
          <span className="text-green">Copy</span>
        </CopyToClipboard>
      </>
    );
  }

  renderDomainValueWithCopy(record) {
    return (
      <>
        { _lodash.truncate(record.value) }
        { ' ' }
        <CopyToClipboard
          text={record.value}
          onCopy={this.handleCopy}
        >
          <span className="text-green">Copy</span>
        </CopyToClipboard>
      </>
    );
  }

  renderRecordsTable(records, receiving = false, spfTooltip = false) {
    if (!records) return null;

    const { domain } = this.state;

    return (
      <div className="table-responsive">
        <table className="table table-hover mb0">
          <thead>
            <tr>
              <th className="border-0 text-uppercase" scope="col">
                Type
              </th>
              <th className="border-0 text-uppercase" scope="col">
                Host Name
              </th>
              {receiving && (
                <th className="border-0 text-uppercase" scope="col">
                  Priority
                </th>
              )}
              <th className="border-0 text-uppercase" scope="col">
                Required Data
              </th>
              <th className="border-0 text-uppercase" scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => (
              <tr key={uuidv4()}>
                <td>{record.record_type}</td>
                <td className="text-break">
                  {spfTooltip && index === records.length - 1 ? (
                    <Tooltipable placement="bottom" text={TooltipText}>
                      {this.renderDomainNameWithCopy(record)}
                    </Tooltipable>
                  ) : (
                    <>
                      {this.renderDomainNameWithCopy(record)}
                    </>
                  )}
                </td>
                {record.priority && (
                  <td className="text-break">
                    Set to any large value not used already. Ex: 50
                    {' '}
                    <CopyToClipboard
                      text={50}
                      onCopy={this.handleCopy}
                    >
                      <span className="text-green">Copy</span>
                    </CopyToClipboard>
                  </td>
                )}
                <td className="text-break">
                  {spfTooltip && index === records.length - 1 ? (
                    <Tooltipable placement="bottom" text={TooltipText}>
                      {this.renderDomainValueWithCopy(record)}
                    </Tooltipable>
                  ) : (
                    <>
                      {this.renderDomainValueWithCopy(record)}
                    </>
                  )}
                </td>
                <td>{this.renderStatus(record.valid)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { domain, submitting, isOpen } = this.state;
    const {
      sending_records,
      tracking_records,
      receiving_records,
      dmarc_record,
    } = domain;
    const dmarcRecords = [
      {
        record_type: 'TXT',
        name:        '_dmarc',
        value:
          'v=DMARC1; p=none; pct=100;',
        valid: !!dmarc_record,
      },
    ];

    return (
      <form>
        <Drawer size="normal" id="sending-domains">
          <div className="modal-header bg-light bt mt-4">
            <div className="modal-title">
              <small className="text-grey">
                Authenticate
                {' '}
                <strong>{domain?.name}</strong>
                {' '}
                with
                Brokerkit by modifying your domain&apos;s DNS records.
                These changes allow your bulk/campaign emails to
                appear to come from
                {' '}
                <strong>{domain?.name}</strong>
                ,
                instead of from our servers. After you&apos;ve made
                the required DNS changes, please wait 24-48 hours for
                the changes to propagate.
                {' '}
                <a
                  href="https://support.getbrokerkit.com/hc/en-us/articles/14655266825485"
                  target="_blank"
                  className="text-green"
                  rel="noreferrer"
                >
                  Learn how to do that?
                </a>
              </small>
            </div>
          </div>
          <div className="modal-body p0">
            <div>
              <h4 className="px-4 pt-3">
                Sending Records
                {' '}
                <span className="h6 text-gray">(Required)</span>
                <ClickableTooltip
                  text={(
                    <>
                      To configure the DNS settings for your email
                      sending domain (e.g. domain.com in
                      name@domain.com).
                      <br />
                      You will need to add the following DNS records
                      to the account where you manage DNS (such as
                      {' '}
                      <a
                        className="text-green"
                        href="https://godaddy.com/help/manage-dns-records-680"
                        target="_blank"
                        rel="noreferrer"
                      >
                        GoDaddy
                      </a>
                      ).
                    </>
                  )}
                  placement="right"
                  target="sending-records-icon"
                >
                  <FontAwesomeIcon
                    icon="fad fa-info-circle"
                    style={{ '--fa-primary-color': 'white' }}
                    id="sending-records-icon"
                    className="ml-2"
                    size="xs"
                  />
                </ClickableTooltip>
              </h4>
              {this.renderRecordsTable(sending_records, false, true)}
            </div>

            <hr />

            <div>
              <h4 className="px-4">
                Tracking Record
                {' '}
                <span className="h6 text-gray">(Required)</span>
                <ClickableTooltip
                  text={(
                    <>
                      To track clicks and opens on your bulk, blast,
                      and campaign emails sent through this sending
                      domain.
                      <br />
                      You will need to add the following DNS record to
                      the account where you manage DNS (such as
                      {' '}
                      <a
                        className="text-green"
                        href="https://godaddy.com/help/manage-dns-records-680"
                        target="_blank"
                        rel="noreferrer"
                      >
                        GoDaddy
                      </a>
                      ).
                    </>
                  )}
                  placement="right"
                  target="tracking-records-icon"
                >
                  <FontAwesomeIcon
                    icon="fad fa-info-circle"
                    style={{ '--fa-primary-color': 'white' }}
                    className="ml-2"
                    size="xs"
                    id="tracking-records-icon"
                  />
                </ClickableTooltip>
              </h4>
              {this.renderRecordsTable(tracking_records)}
            </div>

            <hr />

            <a
              className="ml-4 text-green"
              onClick={this.onCollapseClick}
              href=""
            >
              {isOpen
                ? 'Hide Advanced Settings'
                : 'Advanced Settings'}
            </a>

            <Collapse className="mt-3" in={isOpen}>
              <div>
                <div>
                  <h4 className="px-4">
                    DMARC Record
                    {' '}
                    <span className="h6 text-gray">(Optional)</span>
                    <ClickableTooltip
                      text={(
                        <>
                          A DMARC record is not required but highly
                          recommended for reliable email delivery.
                          {' '}
                          <a
                            href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/14657303630093-implementing-dmarc"
                            target="_blank"
                            className="text-green"
                            rel="noreferrer"
                          >
                            Learn more
                          </a>
                          <br />
                          You will need to add the following DNS record
                          to the account where you manage DNS (such as
                          {' '}
                          <a
                            className="text-green"
                            href="https://godaddy.com/help/manage-dns-records-680"
                            target="_blank"
                            rel="noreferrer"
                          >
                            GoDaddy
                          </a>
                          ).
                        </>
                    )}
                      placement="right"
                      target="dmarc-records-icon"
                    >
                      <FontAwesomeIcon
                        icon="fad fa-info-circle"
                        style={{ '--fa-primary-color': 'white' }}
                        className="ml-2"
                        id="dmarc-records-icon"
                        size="xs"
                      />
                    </ClickableTooltip>
                  </h4>

                  {this.renderRecordsTable(dmarcRecords)}
                </div>

                <hr />

                <div>
                  <h4 className="px-4">
                    Receiving Records
                    {' '}
                    <span className="h6 text-gray">(Optional)</span>
                    <ClickableTooltip
                      text={(
                        <>
                          MX records are not required but highly
                          recommended for reliable email delivery.
                          Properly configured MX records can also
                          improve email deliverability by increasing
                          your email&apos;s trustworthiness and reducing
                          the likelihood of it being marked as spam.
                          <br />
                          You will need to add the following DNS records
                          to the account where you manage DNS (such as
                          {' '}
                          <a
                            className="text-green"
                            href="https://godaddy.com/help/manage-dns-records-680"
                            target="_blank"
                            rel="noreferrer"
                          >
                            GoDaddy
                          </a>
                          ).
                        </>
                    )}
                      placement="right"
                      target="receiving-records-icon"
                    >
                      <FontAwesomeIcon
                        icon="fad fa-info-circle"
                        style={{ '--fa-primary-color': 'white' }}
                        className="ml-2"
                        id="receiving-records-icon"
                        size="xs"
                      />
                    </ClickableTooltip>
                  </h4>

                  {this.renderRecordsTable(receiving_records, true)}
                </div>
              </div>
            </Collapse>

            <div className="my-3">
              {submitting ? (
                <button
                  type="button"
                  className="btn btn-success disabled ml-4"
                  disabled
                >
                  <FontAwesomeIcon icon="far fa-spinner" pulse />
                  {' '}
                  Authenticating ...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success ml-4"
                  onClick={this.verifyDomain}
                >
                  <FontAwesomeIcon icon={['fas', 'key']} />
                  {' '}
                  Authenticate Domain
                </button>
              )}

              <button
                type="button"
                className="btn btn-secondary btn ml-4"
                onClick={this.onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </Drawer>
      </form>
    );
  }
}

EmailDomainDrawer.contextTypes = {
  helpers: PropTypes.shape({}),
};

EmailDomainDrawer.defaultProps = {};

EmailDomainDrawer.propTypes = {
  domain: PropTypes.shape({}).isRequired,
};

export default EmailDomainDrawer;
